import React from "react";
import styled from "styled-components";
import { useIntl, navigate } from "gatsby-plugin-intl";
import { Grid, Row, Col } from "react-styled-flexboxgrid";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Title from "../components/title";
import Button from "../components/button";
import { color } from "src/styled/variables";
import AllSpeakers from "../components/allSpeakers";
import media from "src/styled/responsive";

const { ilo_yellow } = color;

const ContentWrapper = styled.div`
  margin: 1.5rem 0 0;

  ${media.sm`
    margin: 0;
  `}
`;

const Spacer = styled.div`
  padding-bottom: 1.75rem;

  ${media.sm`
    padding: 0;
  `}
`;

const StyledSection = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 2rem 0 0;

  ${media.sm`
    flex-flow: column-reverse;
  `}

  ${media.xs`
    padding: 0;
  `}
`;

const StyledButtonWrapper = styled.div`
  ${media.sm`
    align-self: flex-end;
    padding-bottom: 40px;
  `}
`;

const Speakers = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "title" })} />
      <Title />
      <ContentWrapper>
        <Spacer>
          <Grid>
            <Row between="lg">
              <Col xs={12} sm={10} smOffset={1} mdOffset={0} md={12}>
                <StyledSection>
                  <h2>
                    {intl.formatMessage({ id: "teaser.home.speakers.title" })}
                  </h2>
                  <StyledButtonWrapper>
                    <Button
                      skin="blue"
                      outline={ilo_yellow}
                      shape="polygon"
                      onClick={() => navigate("/programme")}
                    >
                      {intl.formatMessage({ id: "teaser.home.fullprogramme" })}
                    </Button>
                  </StyledButtonWrapper>
                </StyledSection>
              </Col>
            </Row>
          </Grid>
        </Spacer>
        <AllSpeakers />
      </ContentWrapper>
    </Layout>
  );
};

export default Speakers;
