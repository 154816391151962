import React from "react";
import PropTypes from "prop-types";
import { useIntl, IntlContextConsumer } from "gatsby-plugin-intl";
import styled from "styled-components";
import { Grid, Row, Col } from "react-styled-flexboxgrid";
import { color } from "src/styled/variables";
import { getDate } from "src/utils/dates";
import Polygon from "./polygon";
import EventSpeakers from "./eventSpeakers";
import { StaticQuery, graphql } from "gatsby";
import media from "src/styled/responsive";

const { ilo_yellow, ilo_dark_blue } = color;

const RegionalEvent = styled.article`
  width: 100%;
  padding: 20px 20px 48px 20px;

  ${media.sm`
    padding: 1rem 2rem;
  `}

  ${media.xs`
    padding: 1rem 0;
  `}
`;

// const GlobalEvent = styled.article`
//   width: 100%;
//   padding: 20px 20px 0 20px;
// `;

const EventDate = styled.h4`
  color: ${ilo_dark_blue};
  display: inline-block;

  &:after {
    content: "";
    width: 100%;
    height: 6px;
    display: block;
    margin: 5px 0 0;
    background-color: ${ilo_yellow};
  }

  ${media.sm`
    margin: 10px 0 10px 0;
    width: 100%;
  `}
`;

const DesktopH3 = styled.h3`
  ${media.sm`
    display: none;
  `}
`;

const MobileH3 = styled.h3`
  display: none;

  ${media.sm`
    display: inline-block;
    margin: 2rem 2rem 0 2rem;
  `}

  ${media.xs`
    margin: 0;
  `}
`;

const Speakers = ({ data }) => {
  const intl = useIntl();

  const regionalEvents = data.allEventJson.nodes
    .filter((event) => event.region.toLowerCase() !== "global")
    .sort((a, b) =>
      new Date(a.time).getDate() > new Date(b.time).getDate() ? 1 : -1
    );

  const globalEvents = data.allEventJson.nodes
    .filter((event) => event.region.toLowerCase() === "global")
    .sort((a, b) =>
      new Date(a.time).getDate() > new Date(b.time).getDate() ? 1 : -1
    );

  const getTitle = (event, locale) => {
    switch (locale) {
      case "es":
        return event.titleEs;
      case "fr":
        return event.titleFr;
      default:
        return event.titleEn;
    }
  };

  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <Grid>
          <Row start="lg">
            <Col
              smOffset={1}
              mdOffset={0}
              lg={3}
              style={{ textAlign: "right" }}
            >
              <DesktopH3>
                {intl.formatMessage({ id: "allspeakers.global.title" })}
              </DesktopH3>
            </Col>
            <Col xs={12} sm={10} smOffset={1} mdOffset={0} md={12} lg={9}>
              <Polygon outline={ilo_yellow}>
                <MobileH3>
                  {intl.formatMessage({ id: "allspeakers.global.title" })}
                </MobileH3>
                {globalEvents.map((event) => (
                  <RegionalEvent key={event.id}>
                    <EventDate>
                      {getTitle(event, currentLocale)},{" "}
                      {getDate(event.time, currentLocale)}
                    </EventDate>
                    <EventSpeakers
                      data={data}
                      skin="light"
                      withAvatar={false}
                      orderByRank={true}
                      eventId={event.id}
                      gridTotalColumn={12}
                      numOfColumn={3}
                    />
                  </RegionalEvent>
                ))}
              </Polygon>
            </Col>
          </Row>
          <Row start="lg" style={{ height: "72px" }}>
            <Col Col lg={12}>
              &nbsp;
            </Col>
          </Row>
          <Row start="lg">
            <Col
              smOffset={1}
              mdOffset={0}
              lg={3}
              style={{ textAlign: "right" }}
            >
              <DesktopH3>
                {intl.formatMessage({ id: "allspeakers.regional" })}
              </DesktopH3>
            </Col>
            <Col xs={12} sm={10} smOffset={1} mdOffset={0} md={12} lg={9}>
              <Polygon outline={ilo_yellow}>
                <MobileH3>
                  {intl.formatMessage({ id: "allspeakers.regional" })}
                </MobileH3>
                {regionalEvents.map((event) => (
                  <RegionalEvent key={event.id}>
                    <EventDate>
                      {event.region}, {getDate(event.time, currentLocale)}
                    </EventDate>
                    <EventSpeakers
                      data={data}
                      skin="light"
                      eventId={event.id}
                      gridTotalColumn={12}
                      numOfColumn={3}
                    />
                  </RegionalEvent>
                ))}
              </Polygon>
            </Col>
          </Row>
          <Row start="lg">
            <Col Col lg={12}>
              &nbsp;
            </Col>
          </Row>
        </Grid>
      )}
    </IntlContextConsumer>
  );
};

export default function AllSpeakers() {
  return (
    <StaticQuery
      query={graphql`
        query {
          allSpeakerJson {
            edges {
              node {
                name
                jobtitleEn
                jobtitleEs
                jobtitleFr
                thumb
                rank
              }
            }
          }
          allEventJson {
            nodes {
              id
              status
              titleEn
              titleEs
              titleFr
              time
              region
              speaker
              duration
            }
          }
        }
      `}
      render={(data) => <Speakers data={data} />}
    />
  );
}

Speakers.propTypes = {
  data: PropTypes.shape({
    allSpeakerJson: PropTypes.shape({
      edges: PropTypes.shape({
        node: PropTypes.shape({
          name: PropTypes.string.isRequired,
          jobtitleEn: PropTypes.string.isRequired,
          jobtitleEs: PropTypes.string.isRequired,
          jobtitleFr: PropTypes.string.isRequired,
          thumb: PropTypes.string,
          rank: PropTypes.number.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    allEventJson: PropTypes.shape({
      nodes: PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
        titleEn: PropTypes.string.isRequired,
        titleEs: PropTypes.string.isRequired,
        titleFr: PropTypes.string.isRequired,
        time: PropTypes.string.isRequired,
        region: PropTypes.string.isRequired,
        speaker: PropTypes.array.isRequired,
        duration: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};
